// libraries
import {
  Router,
  FilledRoute,
} from "@cloudspire/legacy-shared/src/libraries/Router";

type GetRouteType = {
  router: Router;
  uri: any;
};

/**
 * Récupère la route courante.
 */
function getRoute({ router, uri }: GetRouteType): FilledRoute | null {
  return router.match(uri);
}

export default getRoute;
