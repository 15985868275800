import React from "react";
import { View, StyleSheet, AccessibilityRole } from "react-native";

import theming from "@cloudspire/legacy-resources/src/constants/theming";

// components
import UIContainer from "@cloudspire/legacy-resources/src/components/UIContainer";
import {
  List,
  ListItem,
} from "@cloudspire/legacy-resources/src/components/List";

// hocs
import withMedia, {
  WithMediaInjectedPropsType,
} from "@cloudspire/legacy-resources/src/hocs/withMedia";

// libraries
import { emToPx } from "@cloudspire/legacy-resources/src/libraries";

const { useTheme } = theming;

type PropsType = {
  logo: React.ReactNode;
  dropdown: React.ReactNode;
  highlight: React.ReactNode;
  localeSelect: React.ReactNode;
} & WithMediaInjectedPropsType;

const styles = StyleSheet.create({
  view: {
    paddingTop: emToPx(0.625),
    paddingRight: 0,
    paddingBottom: emToPx(0.625),
    paddingLeft: 0,
    backgroundColor: "#fff",
    borderBottomStyle: "solid",
    borderBottomWidth: emToPx(0.125),
    height: "5rem",
  },
  container: {
    flexDirection: "row",
    height: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logo: {
    flex: 0,
  },
  elements: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    flexDirection: "row",
  },
});

const Header: React.FunctionComponent<PropsType> = ({
  logo,
  dropdown,
  highlight,
  localeSelect,
  matches,
}) => {
  const theme = useTheme();

  return (
    <View
      style={[
        styles.view,
        {
          borderBottomColor: theme.SECONDARY_COLOR,
        },
      ]}
      accessibilityRole={"banner" as AccessibilityRole}
    >
      <UIContainer style={styles.container} isFullWidth={true}>
        <View style={styles.logo}>{logo}</View>

        <View style={styles.elements}>
          {!matches && <View>{highlight}</View>}
          {!matches && <View>{localeSelect}</View>}
          {matches && <View>{dropdown}</View>}
        </View>
      </UIContainer>
    </View>
  );
};

export default withMedia({ query: { maxWidth: 1064 }, initialMatches: true })(
  Header
);
