import React from "react";

import Media from "../../components/Media";

export type WithMediaInjectedPropsType = {
  matches: boolean;
};

type WithMediaWrappedProps = {};

const withMedia =
  ({
    query,
    breakpointType = "viewport",
    initialMatches,
  }: {
    query;
    breakpointType?: "viewport" | "element";
    initialMatches?: boolean;
  }) =>
  <P extends WithMediaInjectedPropsType>(
    WrappedComponent: React.ComponentType<P>
  ) => {
    const WrapperComponent: React.FunctionComponent<
      Omit<P & WithMediaWrappedProps, keyof WithMediaInjectedPropsType>
    > = (props) => {
      const { ...otherProps } = props;

      return (
        <Media
          query={query}
          breakpointType={breakpointType}
          initialMatches={initialMatches}
        >
          {(matches) => (
            <WrappedComponent matches={matches} {...(otherProps as P)} />
          )}
        </Media>
      );
    };

    return WrapperComponent;
  };

export default withMedia;
