// dependencies
import React from "react";
import { View, StyleSheet, GestureResponderEvent } from "react-native";

// libraries
import { emToPx } from "../../libraries";

// components
import ModalClose from "./ModalClose";

type PropsType = {
  onShouldClose: (event: GestureResponderEvent) => void;
};

const styles = StyleSheet.create({
  modalHeader: {
    width: "100%",
    backgroundColor: "#156543",
    padding: "1em",
    // box-shadow: 0 0 15px 5px rgba(0,0,0,.5);
    borderTopLeftRadius: emToPx(0.25),
    borderTopRightRadius: emToPx(0.25),
    borderColor: "rgba(0, 0, 0, .25)",
    borderLeftWidth: emToPx(0.0625),
    borderRightWidth: emToPx(0.0625),
    borderTopWidth: emToPx(0.0625),
  },
  wrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  header: {
    justifyContent: "center",
    flexShrink: 1,
  },
});

const ModalHeader: React.FunctionComponent<PropsType> = (props) => {
  const { children, onShouldClose } = props;

  return (
    <View style={styles.modalHeader}>
      <View style={styles.wrapper}>
        <View style={styles.header}>{children}</View>

        <ModalClose onShouldClose={onShouldClose} />
      </View>
    </View>
  );
};

export default ModalHeader;
