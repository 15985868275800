// constants
import { METHOD_VALUE_GET } from "@cloudspire/legacy-shared/src/constants/methods";

// utils
import {
  flattenDeepPropertyList,
  recursiveBracket,
} from "@cloudspire/legacy-shared/src/libraries";

type FormRedirectType = {
  url: string;
  method?: string;
  data?: object;
};

/**
 * Redirige l’utilisateur vers une autre page avec une méthode HTTP particulière en utilisant un formulaire.
 * @param {object} param0
 * @param {string} param0.url
 * @param {string} [param0.method="GET"]
 * @param {object} [param0.data={}]
 */
const formRedirect = ({
  url,
  method = METHOD_VALUE_GET,
  data = {},
}: FormRedirectType) => {
  const form = document.createElement("form");

  document.body.appendChild(form);

  form.method = method;
  form.action = url;

  flattenDeepPropertyList(data).forEach(([fieldName, fieldValue]) => {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = recursiveBracket(fieldName);
    input.value = fieldValue;

    form.appendChild(input);
  });

  form.submit();
};

export default formRedirect;
