// dependencies
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { View, Dimensions, ScaledSize, LayoutChangeEvent } from "react-native";

// libraries
import { matchQuery } from "../../libraries";

export type IQuery = {
  minWidth?: string | number;
  maxWidth?: string | number;
  minHeight?: string | number;
  maxHeight?: string | number;
};

/**
 * Fonction initial pour onChange
 */
function initialOnChange() {
  void 0;
}

type Props = {
  breakpointType?: "viewport" | "element";
  query: IQuery;
  initialMatches?: boolean;
  onChange?: (matches: boolean) => void;
  render?: () => JSX.Element;
  children: (matches: boolean) => JSX.Element;
};

function Media(props: Props) {
  const {
    breakpointType = "viewport",
    initialMatches = false,
    query,
    render,
    children,
    onChange = initialOnChange,
  } = props;

  const [matches, setMatches] = useState(initialMatches);

  const computeMatch = useCallback(
    function computeMatch(size: ScaledSize) {
      const { width, height } = size;

      setMatches((previousMatches) => {
        const newMatches = matchQuery({ query, width, height });

        if (previousMatches !== newMatches) {
          onChange(newMatches);
        }

        return newMatches;
      });
    },
    [query, onChange]
  );

  const handleChangeDimensions = useCallback(
    function handleChangeDimensions({ window }) {
      computeMatch(window);
    },
    [computeMatch]
  );

  const handleLayout = useCallback(
    function handleLayout(event: LayoutChangeEvent): void {
      computeMatch(event.nativeEvent.layout);
    },
    [computeMatch]
  );

  useEffect(
    function () {
      if (breakpointType === "viewport") {
        Dimensions.addEventListener("change", handleChangeDimensions);

        // Valeur initiale
        handleChangeDimensions({
          window: Dimensions.get("window"),
        });

        return function () {
          Dimensions.removeEventListener("change", handleChangeDimensions);
        };
      }
    },
    [breakpointType, handleChangeDimensions]
  );

  return (
    <View {...(breakpointType === "element" && { onLayout: handleLayout })}>
      {matches && "function" === typeof render ? (
        <Fragment>{render()}</Fragment>
      ) : (
        <Fragment>
          {"function" === typeof children && (
            <Fragment>{children(matches)}</Fragment>
          )}
        </Fragment>
      )}
    </View>
  );
}

export default Media;
