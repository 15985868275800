// Alias des pages administrables

/** Découvrir le département */
export const CONTENT_ALIAS_VALUE_DISCOVER = "discover";
/** Qui sommes-nous */
export const CONTENT_ALIAS_VALUE_ABOUT = "about";
/** Espace presse */
export const CONTENT_ALIAS_VALUE_PRESS = "press";
/** Partenaires */
export const CONTENT_ALIAS_VALUE_PARTNER = "partner";
/** Rejoignez-nous */
export const CONTENT_ALIAS_VALUE_LABEL = "label";
/** FAQ */
export const CONTENT_ALIAS_VALUE_FAQ = "faq";
/** Mentions légales */
export const CONTENT_ALIAS_VALUE_LEGAL = "legal";
/** Conditions générales d’utilisation */
export const CONTENT_ALIAS_VALUE_CGU = "cgu";
/** Conditions générales de vente */
export const CONTENT_ALIAS_VALUE_CGV = "cgv";
/** Politique de confidentialité */
export const CONTENT_ALIAS_VALUE_PRIVACY = "privacy";
/** Contact */
export const CONTENT_ALIAS_VALUE_CONTACT = "contact";
/** Informations pratiques */
export const CONTENT_ALIAS_VALUE_INFOS = "infos";
/** Assurance annulation */
export const CONTENT_ALIAS_VALUE_INSURANCE = "insurance";
/** Liste des communes */
export const CONTENT_ALIAS_VALUE_CITY = "city";
/** Texte de présentation */
export const CONTENT_ALIAS_VALUE_HOME = "home";
/** Assurance classique (utilisé dans les PDF) */
export const CONTENT_ALIAS_VALUE_INSURANCE_PAR = "insurance-par";
/** Assurance professionnelle (utilisé dans les PDF) */
export const CONTENT_ALIAS_VALUE_INSURANCE_PRO = "insurance-pro";
/** Conditions Générales Particuliers (utilisé dans les PDF) */
export const CONTENT_ALIAS_VALUE_TERMS_PAR = "terms-par";
/** Conditions Générales professionnels (utilisé dans les PDF) */
export const CONTENT_ALIAS_VALUE_TERMS_PRO = "terms-pro";
/** Conditions Générales Particuliers LD (utilisé dans les PDF) */
export const CONTENT_ALIAS_VALUE_TERMS_LD_PAR = "terms-ld-par";
/** Conditions Générales professionnels LD (utilisé dans les PDF) */
export const CONTENT_ALIAS_VALUE_TERMS_LD_PRO = "terms-ld-pro";
/** Voucher (utilisé dans les PDF) */
export const CONTENT_ALIAS_VALUE_VOUCHER = "voucher";
/** Autres */
export const CONTENT_ALIAS_VALUE_OTHERS = "others";

export const CONTENT_ALIASES = {
  [CONTENT_ALIAS_VALUE_DISCOVER]: {
    id: CONTENT_ALIAS_VALUE_DISCOVER,
    label: "découvrir le département",
    include_in_sitemap: true,
    use_in_pdf: false,
  },
  [CONTENT_ALIAS_VALUE_ABOUT]: {
    id: CONTENT_ALIAS_VALUE_ABOUT,
    label: "qui sommes-nous",
    include_in_sitemap: true,
    use_in_pdf: false,
  },
  [CONTENT_ALIAS_VALUE_PRESS]: {
    id: CONTENT_ALIAS_VALUE_PRESS,
    label: "espace presse",
    include_in_sitemap: true,
    use_in_pdf: false,
  },
  [CONTENT_ALIAS_VALUE_PARTNER]: {
    id: CONTENT_ALIAS_VALUE_PARTNER,
    label: "partenaires",
    include_in_sitemap: true,
    use_in_pdf: false,
  },
  [CONTENT_ALIAS_VALUE_LABEL]: {
    id: CONTENT_ALIAS_VALUE_LABEL,
    label: "rejoignez-nous",
    include_in_sitemap: true,
    use_in_pdf: false,
  },
  [CONTENT_ALIAS_VALUE_FAQ]: {
    id: CONTENT_ALIAS_VALUE_FAQ,
    label: "FAQ",
    include_in_sitemap: true,
    use_in_pdf: false,
  },
  [CONTENT_ALIAS_VALUE_LEGAL]: {
    id: CONTENT_ALIAS_VALUE_LEGAL,
    label: "mentions légales",
    include_in_sitemap: true,
    use_in_pdf: false,
  },
  [CONTENT_ALIAS_VALUE_CGU]: {
    id: CONTENT_ALIAS_VALUE_CGU,
    label: "conditions générales d’utilisation",
    include_in_sitemap: true,
    use_in_pdf: false,
  },
  [CONTENT_ALIAS_VALUE_CGV]: {
    id: CONTENT_ALIAS_VALUE_CGV,
    label: "conditions générales de vente",
    include_in_sitemap: true,
    use_in_pdf: false,
  },
  [CONTENT_ALIAS_VALUE_PRIVACY]: {
    id: CONTENT_ALIAS_VALUE_PRIVACY,
    label: "politique de confidentialité",
    include_in_sitemap: true,
    use_in_pdf: false,
  },
  [CONTENT_ALIAS_VALUE_CONTACT]: {
    id: CONTENT_ALIAS_VALUE_CONTACT,
    label: "contact",
    include_in_sitemap: true,
    use_in_pdf: false,
  },
  [CONTENT_ALIAS_VALUE_INFOS]: {
    id: CONTENT_ALIAS_VALUE_INFOS,
    label: "informations pratiques",
    include_in_sitemap: true,
    use_in_pdf: false,
  },
  [CONTENT_ALIAS_VALUE_INSURANCE]: {
    id: CONTENT_ALIAS_VALUE_INSURANCE,
    label: "assurance annulation",
    include_in_sitemap: true,
    use_in_pdf: false,
  },
  [CONTENT_ALIAS_VALUE_CITY]: {
    id: CONTENT_ALIAS_VALUE_CITY,
    label: "liste des communes",
    include_in_sitemap: true,
    use_in_pdf: false,
  },
  [CONTENT_ALIAS_VALUE_HOME]: {
    id: CONTENT_ALIAS_VALUE_HOME,
    label: "texte de présentation",
    include_in_sitemap: true,
    use_in_pdf: false,
  },
  [CONTENT_ALIAS_VALUE_INSURANCE_PAR]: {
    id: CONTENT_ALIAS_VALUE_INSURANCE_PAR,
    label: "assurance classique",
    include_in_sitemap: false,
    use_in_pdf: true,
  },
  [CONTENT_ALIAS_VALUE_INSURANCE_PRO]: {
    id: CONTENT_ALIAS_VALUE_INSURANCE_PRO,
    label: "assurance professionnelle",
    include_in_sitemap: false,
    use_in_pdf: true,
  },
  [CONTENT_ALIAS_VALUE_TERMS_PAR]: {
    id: CONTENT_ALIAS_VALUE_TERMS_PAR,
    label: "condition Générale Particuliers",
    include_in_sitemap: false,
    use_in_pdf: true,
  },
  [CONTENT_ALIAS_VALUE_TERMS_PRO]: {
    id: CONTENT_ALIAS_VALUE_TERMS_PRO,
    label: "conditions Générales professionnels",
    include_in_sitemap: false,
    use_in_pdf: true,
  },
  [CONTENT_ALIAS_VALUE_TERMS_LD_PAR]: {
    id: CONTENT_ALIAS_VALUE_TERMS_LD_PAR,
    label: "condition Générale Particuliers LD",
    include_in_sitemap: false,
    use_in_pdf: true,
  },
  [CONTENT_ALIAS_VALUE_TERMS_LD_PRO]: {
    id: CONTENT_ALIAS_VALUE_TERMS_LD_PRO,
    label: "conditions Générales professionnels LD",
    include_in_sitemap: false,
    use_in_pdf: true,
  },
  [CONTENT_ALIAS_VALUE_VOUCHER]: {
    id: CONTENT_ALIAS_VALUE_VOUCHER,
    label: "voucher",
    include_in_sitemap: false,
    use_in_pdf: true,
  },
  [CONTENT_ALIAS_VALUE_OTHERS]: {
    id: CONTENT_ALIAS_VALUE_OTHERS,
    label: "autres",
    include_in_sitemap: false,
    use_in_pdf: false,
  },
};

export const CONTENT_ALIAS_LIST = Object.values(CONTENT_ALIASES);

/* Type de contenu */

/** Page requise */
export const CONTENT_TYPE_VALUE_PAGE = "pa";
/** Page libre */
export const CONTENT_TYPE_VALUE_FREE = "fr";
/** Page de catégorie */
export const CONTENT_TYPE_VALUE_CATEGORY = "ca";
/** Page de campagne */
export const CONTENT_TYPE_VALUE_CAMPAIGN = "cp";

export const CONTENT_TYPES = {
  [CONTENT_TYPE_VALUE_PAGE]: {
    id: CONTENT_TYPE_VALUE_PAGE,
    label: "page requise",
  },
  [CONTENT_TYPE_VALUE_FREE]: {
    id: CONTENT_TYPE_VALUE_FREE,
    label: "page libre",
  },
  [CONTENT_TYPE_VALUE_CATEGORY]: {
    id: CONTENT_TYPE_VALUE_CATEGORY,
    label: "page de catégorie",
  },
  [CONTENT_TYPE_VALUE_CAMPAIGN]: {
    id: CONTENT_TYPE_VALUE_CAMPAIGN,
    label: "page de campagne",
  },
};

export const CONTENT_TYPE_LIST = Object.values(CONTENT_TYPES);

/** Brouillon */
export const CONTENT_STATE_VALUE_REGISTERED = "re";
/** Publié */
export const CONTENT_STATE_VALUE_ENABLED = "en";
/** Désactivé */
export const CONTENT_STATE_VALUE_DISABLED = "di";
/** Supprimé */
export const CONTENT_STATE_VALUE_TRASHED = "tr";

export const CONTENT_STATES = {
  [CONTENT_STATE_VALUE_REGISTERED]: {
    id: CONTENT_STATE_VALUE_REGISTERED,
    label: "brouillon",
    color: "#333333",
  },
  [CONTENT_STATE_VALUE_ENABLED]: {
    id: CONTENT_STATE_VALUE_ENABLED,
    label: "publié",
    color: "#27c24c",
  },
  [CONTENT_STATE_VALUE_DISABLED]: {
    id: CONTENT_STATE_VALUE_DISABLED,
    label: "désactivé",
    color: "#442277",
  },
  [CONTENT_STATE_VALUE_TRASHED]: {
    id: CONTENT_STATE_VALUE_TRASHED,
    label: "archivé",
    color: "#f05050",
  },
};

export const CONTENT_STATE_LIST = Object.values(CONTENT_STATES);

// Catégorie de page

/** Par défaut : affiché dans le pied de page */
export const CONTENT_CATEGORY_VALUE_DEFAULT = "footer";
/** Menu : affiché dans le menu principal */
export const CONTENT_CATEGORY_VALUE_MENU = "menu";
/** Bottom : affiché dans la bottombar */
export const CONTENT_CATEGORY_VALUE_BOTTOM = "bottom";
/** Home : affiché sur la page d'accueil */
export const CONTENT_CATEGORY_VALUE_HOME = "home";
/** Theme : affiché sur la page thématique */
export const CONTENT_CATEGORY_VALUE_THEME = "theme";
/** City : affiché sur la liste des villes */
export const CONTENT_CATEGORY_VALUE_CITY = "city";
/** Promo : affiché sur la page des promos */
export const CONTENT_CATEGORY_VALUE_PROMO = "promo";

export const CONTENT_CATEGORIES = {
  [CONTENT_CATEGORY_VALUE_DEFAULT]: {
    id: CONTENT_CATEGORY_VALUE_DEFAULT,
  },
  [CONTENT_CATEGORY_VALUE_MENU]: {
    id: CONTENT_CATEGORY_VALUE_MENU,
  },
  [CONTENT_CATEGORY_VALUE_BOTTOM]: {
    id: CONTENT_CATEGORY_VALUE_BOTTOM,
  },
  [CONTENT_CATEGORY_VALUE_HOME]: {
    id: CONTENT_CATEGORY_VALUE_HOME,
  },
  [CONTENT_CATEGORY_VALUE_THEME]: {
    id: CONTENT_CATEGORY_VALUE_THEME,
  },
  [CONTENT_CATEGORY_VALUE_CITY]: {
    id: CONTENT_CATEGORY_VALUE_CITY,
  },
  [CONTENT_CATEGORY_VALUE_PROMO]: {
    id: CONTENT_CATEGORY_VALUE_PROMO,
  },
};

export const CONTENT_CATEGORY_LIST = Object.values(CONTENT_CATEGORIES);
