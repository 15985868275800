// constants
import { CONTENT_ALIAS_VALUE_LABEL } from "@cloudspire/shared/constants/content";

// libraries
import { get, generateUri } from "@cloudspire/legacy-shared/src/libraries";

// helpers
import { contentGenerateUriRelayOnType } from "@cloudspire/legacy-resources/src/helpers/content";

type LayoutGetHeaderMenuLinkListParam0Type = {
  intl: import("react-intl").IntlShape;
  configuration: ReturnType<
    typeof import("@cloudspire/legacy-resources/src/normalizers/configuration").configurationParseData
  >;
  frontRouter: import("@cloudspire/legacy-shared/src/libraries/Router").Router;
  accountRouter: import("@cloudspire/legacy-shared/src/libraries/Router").Router;
  currentPath: string;
};

const layoutGetHeaderMenuLinkList = ({
  intl,
  configuration,
  frontRouter,
  accountRouter,
  currentPath,
}: LayoutGetHeaderMenuLinkListParam0Type) => {
  return [
    {
      label: intl.formatMessage({
        defaultMessage: "Mon espace",
      }),
      to: generateUri({
        router: accountRouter,
        name: "App.Action.Auth.Login",
        parameters: {
          lang: intl.locale,
        },
      }),
      isActivated: false,
    },
    ...(get(configuration, "channel.website.contents.bySection.promo", [])
      .length > 0
      ? [
          {
            label: intl.formatMessage({
              defaultMessage: "Bons plans",
            }),
            to: generateUri({
              router: frontRouter,
              name: "App.Action.DealList",
              parameters: {
                lang: intl.locale,
              },
            }),
            type: "highlight",
            isActivated:
              generateUri({
                name: "App.Action.DealList",
                router: frontRouter,
                parameters: {
                  lang: intl.locale,
                },
              }) === currentPath,
          },
        ]
      : []),
    ...get(configuration, "channel.website.contents.bySection.menu", []).map(
      (content) => ({
        label: content.title,
        to: contentGenerateUriRelayOnType({ intl, content, frontRouter }),
        isActivated: false,
      })
    ),
    {
      label: intl.formatMessage({
        defaultMessage: "Rechercher",
      }),
      to: generateUri({
        router: frontRouter,
        name: "App.Action.RoomList",
        parameters: {
          lang: intl.locale,
        },
      }),
    },

    ...(configuration.channel.website.contents.byAliases[
      CONTENT_ALIAS_VALUE_LABEL
    ]
      ? [
          {
            label:
              configuration.channel.website.contents.byAliases[
                CONTENT_ALIAS_VALUE_LABEL
              ].title,
            to: generateUri({
              router: frontRouter,
              name: "App.Action.Content",
              parameters: {
                lang: intl.locale,
                slug: configuration.channel.website.contents.byAliases[
                  CONTENT_ALIAS_VALUE_LABEL
                ].slug,
              },
            }),
          },
        ]
      : []),
    {
      label: intl.formatMessage({
        defaultMessage: "Nous contacter",
      }),
      to: generateUri({
        router: frontRouter,
        name: "App.Action.Contact",
        parameters: {
          lang: intl.locale,
        },
      }),
      isActivated: false,
    },
  ];
};

export default layoutGetHeaderMenuLinkList;
