/**
 * Initial predicate function
 * @param {any} item
 * @param {number} itemIndex
 * @param {any[]} array
 * @returns {boolean}
 */
function initialPredicate(item, itemIndex, array) {
  return true;
}

/**
 * Filter an array
 * @param {any[]} arr
 * @param {(value: any, index: number, array: any[]) => boolean} predicate
 * @returns {any[]}
 */
export default function arrayFilter(arr, predicate = initialPredicate) {
  return arr.filter(predicate);
}
