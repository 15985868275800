import EnhancedDate from "../EnhancedDate";

/**
 * Renvoie vrai si l'ensemble des dates passées en paramètres sont égales.
 */
const isEqual = (...dateList: EnhancedDate[]) => {
  for (let i = 0; i < dateList.length - 1; i++) {
    const date1 = dateList[i];
    const date2 = dateList[i + 1];

    if (!(date1 instanceof EnhancedDate && date1.isEqualTo(date2))) {
      return false;
    }
  }

  return true;
};

export default isEqual;
