// dependencies
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Animated,
  View,
  StyleSheet,
  ViewStyle,
  StyleProp,
  TouchableWithoutFeedback,
} from "react-native";

// libraries
import functionNoop from "@cloudspire/shared/utils/function/noop";

// constants
import { DRAWER as DRAWER_Z_INDEX } from "../../constants/zIndex";

const styles = StyleSheet.create({
  view: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
    zIndex: DRAWER_Z_INDEX,
    pointerEvents: "auto",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  content: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "#ffffff",
    shadowColor: "#000000",
    shadowRadius: 25,
    shadowOffset: { width: 10, height: 0 },
    overflow: "auto",
  },
});

type Props = {
  contentStyle?: StyleProp<ViewStyle>;
  isOpened: boolean;
  shouldCloseOnClickOutside?: boolean;
  shouldCloseOnEscape?: boolean;
  children: React.ReactNode;
  onHide?: () => void;
  onOpen?: () => void;
  onShouldClose?: () => void;
};

function Drawer(props: Props) {
  const {
    isOpened: isOpen,
    contentStyle,
    shouldCloseOnClickOutside = true,
    shouldCloseOnEscape = true,
    children,
    onShouldClose = functionNoop,
    onHide = functionNoop,
    onOpen = functionNoop,
  } = props;

  const [animatedIsOpened, setAnimatedIsOpened] = useState(isOpen);

  useEffect(
    function () {
      if (isOpen) {
        const { scrollX: previousScrollX, scrollY: previousScrollY } = window;
        document.body.dataset["lockScroll"] = "true";
        document.body.style.top = `-${previousScrollY}px`;
        document.body.style.left = `-${previousScrollX}px`;

        return function () {
          delete document.body.dataset["lockScroll"];
          document.body.style.top = "";
          document.body.style.left = "";
          window.scrollTo(previousScrollX, previousScrollY);
        };
      }
    },
    [isOpen]
  );

  const animation = useRef(new Animated.Value(isOpen ? 1 : 0)).current;

  useEffect(
    function () {
      if (isOpen) {
        setAnimatedIsOpened(true);
      }
      Animated.timing(animation, {
        toValue: isOpen ? 1 : 0,
        duration: 160,
        useNativeDriver: false,
      }).start(function () {
        if (isOpen) {
          onOpen();
        } else {
          setAnimatedIsOpened(false);
          onHide();
        }
      });
    },
    [isOpen, animation, onOpen, onHide]
  );

  const dynamicStyles = useMemo(
    () =>
      StyleSheet.create({
        view: {
          visibility: animatedIsOpened ? "visible" : "hidden",
        },
      }),
    [animatedIsOpened]
  );

  const animationStyles = useMemo(
    () =>
      StyleSheet.create({
        overlay: {
          backgroundColor: animation.interpolate({
            inputRange: [0, 1],
            outputRange: ["rgba(0, 0, 0, 0)", "rgba(0, 0, 0, 0.65)"],
          }),
        },
        content: {
          transform: [
            {
              translateX: animation.interpolate({
                inputRange: [0, 1],
                outputRange: ["100%", "0%"],
              }),
            },
          ],
        },
      }),
    [animation]
  );

  function handlePressOutside() {
    if (shouldCloseOnClickOutside) {
      onShouldClose();
    }
  }

  useEffect(
    function () {
      if (shouldCloseOnEscape) {
        const handleKeyDown = function (event: KeyboardEvent) {
          if (event.key === "Escape") {
            onShouldClose();
          }
        };
        window.addEventListener("keydown", handleKeyDown);
        return function () {
          window.removeEventListener("keydown", handleKeyDown);
        };
      }
    },
    [shouldCloseOnEscape, onShouldClose]
  );

  return (
    <View
      style={[styles.view, dynamicStyles.view]}
      importantForAccessibility={!isOpen ? "no-hide-descendants" : "auto"}
      aria-hidden={!isOpen}
    >
      <TouchableWithoutFeedback onPress={handlePressOutside}>
        <Animated.View style={[styles.overlay, animationStyles.overlay]} />
      </TouchableWithoutFeedback>

      <Animated.View
        style={[styles.content, animationStyles.content, contentStyle]}
      >
        {children}
      </Animated.View>
    </View>
  );
}

export default Drawer;
