import Router from "next/router";

type IRedirect = {
  res?;
  to: string;
  dynamic?;
};

/**
 * @param {object} param0
 * @param {object} [param0.res] La réponse (dans le cas du serveur)
 * @param {string} param0.to Le lien où rediriger l'utilisateur
 * @param {boolean} [param0.dynamic=false] Pour modifier dynamiquement l'URL sans recharger la page (côté client)
 */
export default async ({ res, to, dynamic = false }: IRedirect) => {
  if (res) {
    res.writeHead(302, {
      Location: to,
    });

    res.end();
  } else {
    if (dynamic) {
      history.replaceState(null, null, to);
    } else {
      await Router.push(to);
    }
  }
};
