// dependencies
import React from "react";
import NextLink from "next/link";
import { StyleSheet } from "aphrodite";

// components
import Text from "@cloudspire/legacy-resources/src/components/Text2";

// contexts
import { useRouters } from "@cloudspire/legacy-resources/src/contexts/routers";

// libraries
import { getRoute } from "@cloudspire/legacy-resources/src/libraries";
import Uri from "@cloudspire/legacy-shared/src/libraries/Uri";

const styles = StyleSheet.create({
  link: {
    textDecoration: "none",
  },
});

type PropsType = {
  to:
    | string
    | import("@cloudspire/legacy-shared/src/libraries/Router").FilledRoute
    | Uri;
  aStyle?;
  external?: boolean;
} & React.ComponentProps<typeof Text>;

const Link: React.FunctionComponent<PropsType> = React.forwardRef(function Link(
  props,
  ref
) {
  const { to, external = false, aStyle, ...otherProps } = props;

  const { mainRouter } = useRouters();

  const { nextLink, href } = getRoute({
    to,
    external,
    router: mainRouter,
  });

  if (nextLink) {
    return (
      <NextLink href={href} passHref={true} legacyBehavior>
        <Text ref={ref} as="a" aStyle={[styles.link, aStyle]} {...otherProps} />
      </NextLink>
    );
  } else {
    return (
      <Text
        ref={ref}
        as="a"
        aStyle={[styles.link, aStyle]}
        href={to as string}
        {...otherProps}
      />
    );
  }
});

export default Link;
