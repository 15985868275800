import { useEffect, useState } from "react";
import ReactDOM from "react-dom";

function Portal(props) {
  const { selector, children } = props;

  const [element, setElement] = useState(null);

  useEffect(
    function () {
      setElement(document.querySelector(selector));
    },
    [selector]
  );

  if (element == null) {
    return null;
  }

  return ReactDOM.createPortal(children, element);
}

export default Portal;
