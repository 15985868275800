// dependencies
import React from "react";
import {
  Animated,
  StyleSheet,
  View,
  TouchableWithoutFeedback,
  GestureResponderEvent,
  ViewStyle,
  findNodeHandle,
  Dimensions,
  Platform,
} from "react-native";

// components
import BaseComponent from "../BaseComponent";

// libraries
import { noop } from "@cloudspire/legacy-shared/src/libraries";
import { rafThrottle } from "../../libraries";

type IProps = {
  opened: boolean;
  shouldCloseOnClickOutside?: boolean;
  shouldCloseOnEscape?: boolean;
  children: React.ReactNode | ((onShouldClose: () => void) => React.ReactNode);
  onHide?: () => void;
  onOpen?: () => void;
  onShouldClose?: () => void;
};

type IState = {
  visible: boolean;
  minHeight: number;
};

class Modal extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps>;

  private animationValue: Animated.Value;

  private animationValueKeyframes;

  private mounted = true;

  private $nodes = {
    content: React.createRef<View>(),
  };

  public constructor(props: IProps) {
    super(props);

    this.animationValue = new Animated.Value(props.opened ? 1 : 0);

    this.animationValueKeyframes = {
      visible: Animated.timing(this.animationValue, {
        toValue: 1,
        duration: 160,
        useNativeDriver: false,
      }),
      hide: Animated.timing(this.animationValue, {
        toValue: 0,
        duration: 160,
        useNativeDriver: false,
      }),
    };

    this.state = {
      visible: props.opened,
      minHeight: 0,
    };

    this.handlePressOutside = this.handlePressOutside.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
    this.handleChangeDimension = rafThrottle(
      this.handleChangeDimension.bind(this)
    );
  }

  public componentDidMount(): void {
    const { opened, shouldCloseOnEscape } = this.props;

    if (shouldCloseOnEscape) {
      this.initKeypress();
    }

    if (opened) {
      this.preventScroll();
    }

    this.initChangeDimension();

    this.computeHeight();
  }

  public componentDidUpdate(prevProps: IProps): void {
    const { opened, shouldCloseOnEscape } = this.props;

    if (prevProps.opened !== opened) {
      if (opened) {
        this.makeVisible();

        this.preventScroll();
      } else {
        this.makeHide();

        this.allowScroll();
      }
    }

    if (prevProps.shouldCloseOnEscape !== shouldCloseOnEscape) {
      if (shouldCloseOnEscape) {
        this.initKeypress();
      } else {
        this.terminateKeypress();
      }
    }
  }

  public componentWillUnmount(): void {
    this.terminateKeypress();
    this.terminateDimension();
    this.allowScroll();

    this.mounted = false;
  }

  private allowScroll() {
    if (Platform.OS === "web") {
      window.document.querySelector("html").style.overflow = null;
    }
  }

  private preventScroll() {
    if (Platform.OS === "web") {
      window.document.querySelector("html").style.overflow = "hidden";
    }
  }

  private initChangeDimension(): void {
    Dimensions.addEventListener("change", this.handleChangeDimension);
  }

  private terminateDimension() {
    Dimensions.removeEventListener("change", this.handleChangeDimension);

    (this.handleChangeDimension as any).cancel();
  }

  private initKeypress(): void {
    document.addEventListener("keydown", this.handleKeydown);
  }

  private terminateKeypress(): void {
    document.removeEventListener("keydown", this.handleKeydown);
  }

  private handleChangeDimension() {
    this.computeHeight();
  }

  private async computeHeight() {
    const { content: $content } = this.$nodes;

    if ($content.current instanceof HTMLElement) {
      const contentHeight = await new Promise<number>((resolve) =>
        $content.current.measure((_1, _2, _3, height) => resolve(height))
      );

      this.setState({ minHeight: contentHeight });
    }
  }

  /**
   * Ce callback n'est appelé que si le prop `shouldCloseOnEscape` vaut `true` et que la modal est ouverte
   */
  private handleKeydown(event: KeyboardEvent): void {
    const { opened } = this.props;

    if (opened && event.code === "Escape") {
      const { onShouldClose } = this.props;

      event.preventDefault();

      onShouldClose();
    }
  }

  private handlePressOutside(event: GestureResponderEvent): void {
    const { shouldCloseOnClickOutside, onShouldClose } = this.props;

    if (
      shouldCloseOnClickOutside &&
      !(
        findNodeHandle(
          this.$nodes.content.current as any
        ) as unknown as HTMLElement
      ).contains(event.target as unknown as HTMLElement)
    ) {
      event.stopPropagation();
      onShouldClose();
    }
  }

  private makeVisible(): void {
    const { onOpen } = this.props;

    this.animationValue.setValue(0);

    // s'assure que la valeur a bien été réinitialisée à 0 avant de lancer la mise à jour du state
    requestAnimationFrame(() => {
      if (this.mounted) {
        this.setState({ visible: true }, () => {
          this.animationValueKeyframes.visible.start(onOpen);
        });
      }
    });
  }

  private makeHide(): void {
    const { onHide } = this.props;

    this.animationValue.setValue(1);
    this.animationValueKeyframes.hide.start(() => {
      if (this.mounted) {
        this.setState({ visible: false }, onHide);
      }
    });
  }

  private getStyles(): StyleSheet.NamedStyles<{
    modal: ViewStyle;
    content: ViewStyle;
    baseComponent: ViewStyle;
  }> {
    const animationValue = this.animationValue;
    const { visible } = this.state;

    return StyleSheet.create({
      modal: {
        position: "fixed" as any,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
        overflow: "auto" as any,
        display: visible ? "flex" : "none",
        opacity: animationValue as unknown as number,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.65)",
        cursor: null,
      },
      content: {
        maxWidth: "100%",
        maxHeight: "100%",
        zIndex: 1,
      },
      baseComponent: {
        maxWidth: "100%",
        maxHeight: "100%",
      },
    });
  }

  public render(): JSX.Element {
    const { opened, children, onShouldClose } = this.props;
    const { minHeight } = this.state;

    const styles = this.getStyles();

    return (
      <TouchableWithoutFeedback onPress={this.handlePressOutside}>
        <Animated.View
          accessibilityRole="dialog"
          aria-modal={opened}
          importantForAccessibility={!opened ? "no-hide-descendants" : "auto"}
          style={[styles.modal, { minHeight }]}
        >
          <BaseComponent containerStyle={styles.baseComponent}>
            <View style={styles.content} ref={this.$nodes.content}>
              {"function" === typeof children
                ? (children as any)(onShouldClose)
                : children}
            </View>
          </BaseComponent>
        </Animated.View>
      </TouchableWithoutFeedback>
    );
  }
}

Modal.defaultProps = {
  shouldCloseOnClickOutside: true,
  shouldCloseOnEscape: true,
  onHide: noop,
  onOpen: noop,
  onShouldClose: noop,
};

export default Modal;
