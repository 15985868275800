/**
 * Initial predicate function
 * @template T
 * @param {T} item
 * @returns {T}
 */
function initialCallback(item) {
  return item;
}

/**
 * Map an array
 * @template T
 * @param {Array<T>} arr
 * @param {(value: T, index: number, array: T[]) => T} callback
 * @returns {Array<T>}
 */
export default function arrayMap(arr, callback = initialCallback) {
  return arr.map(callback);
}
