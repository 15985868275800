// intl
import intlConfig from "../../intl/intl.config";

// constants
import browser from "@cloudspire/legacy-resources/src/constants/browser";
import { NEXT_INTL_MESSAGES_WINDOW_KEY } from "@cloudspire/legacy-resources/src/constants/next";

/**
 * Retourne les messages de traduction pour une langue donnée.
 * @param {object} param0
 * @param {string} param0.locale
 * @return {Promise<object>}
 */
export default async function nextGetIntlMessages({ locale }) {
  // Si les messages de traductions existent sur l'objet window pour la langue demandée, on les retourne.
  if (browser && window?.[NEXT_INTL_MESSAGES_WINDOW_KEY]?.[locale]) {
    return window[NEXT_INTL_MESSAGES_WINDOW_KEY][locale];
  }

  const messages = await import(
    `../../intl/compiled-messages/${locale}.json`
  ).catch(() => {
    return import(`../../intl/compiled-messages/${intlConfig.mainLocale}.json`);
  });

  return messages;
}
