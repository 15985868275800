// libraries
import {
  ObjectCacheManager,
  RedisCacheManager,
} from "../libraries/CacheManager";

function getCache() {
  if ("undefined" === typeof window) {
    return import("./getRedisConnection")
      .then(({ default: getRedisConnection }) => getRedisConnection)
      .then(
        (getRedisConnection) => new RedisCacheManager(getRedisConnection())
      );
  } else {
    return new ObjectCacheManager();
  }
}

export default getCache;
