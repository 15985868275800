// dependencies
import React from "react";
import { View, StyleSheet } from "react-native";

// libraries
import { emToPx } from "../../libraries";

type PropsType = {};

const styles = StyleSheet.create({
  modalContent: {
    overflow: "hidden",
    backgroundColor: "#ffffff",
    borderBottomLeftRadius: emToPx(0.25),
    borderBottomRightRadius: emToPx(0.25),
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, .75)",
    borderLeftWidth: emToPx(0.0625),
    borderRightWidth: emToPx(0.0625),
    borderBottomWidth: emToPx(0.0625),
  },
});

const ModalContent: React.FunctionComponent<PropsType> = (props) => {
  const { children } = props;

  return <View style={styles.modalContent}>{children}</View>;
};

export default ModalContent;
