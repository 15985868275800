// libraires
import { fromString } from "../../EnhancedDate";

/**
 * Converti une date avec le support du format legacy.
 */
const parseDateInQuery = ({ date }) => {
  if ("string" === typeof date) {
    let newDate = fromString(date, "Y-m-d");

    if (!newDate) {
      newDate = fromString(date, "d/m/Y");
    }

    if (newDate) {
      return newDate.format("Y-m-d");
    }
  }

  return undefined;
};

export default parseDateInQuery;
