// constants
import browser from "../../constants/browser";
import { NEXT_CHANNEL_CONFIGURATION_WINDOW_KEY } from "../../constants/next";

/**
 * Stocke les informations de base du channel dans l’objet `window`.
 * @param {object} param0
 * @param {object} param0.req
 */
export default function nextSetChannelConfiguration({ channelConfiguration }) {
  if (browser) {
    window[NEXT_CHANNEL_CONFIGURATION_WINDOW_KEY] = channelConfiguration;
  }
}
