/**
 * Ne fait rien
 * Litéralement
 * Absolument rien
 * Pas de lignes cachées
 * Rien du tout
 * Nada
 * Niente
 * C'est bon j'arrête, Copilot est essoufflé d'avoir autant travaillé
 * @param {...any} args
 * @returns {void}
 */
export default function functionNoop(...args) {
  void 0;
}
