import browser from "../../constants/browser";

/**
 * Récupère l’Uri courant.
 * @param {object} [param0]
 * @param {string} [param0.pathname]
 * @param {object} [param0.query]
 * @param {import("http").IncomingMessage} [param0.req]
 * @return {URL}
 */
export default function nextGetUri(param0 = {}) {
  const { pathname, req } = param0;
  const query = { ...(param0.query ?? {}) };

  const protocol = browser
    ? window.location.protocol
    : `${req.headers["x-forwarded-proto"] ?? req.protocol ? "https" : "http"}:`;
  const host = browser
    ? window.location.host
    : req.headers["x-forwarded-host"] ?? req.headers.host;

  const authority = `${protocol}//${host}`;

  const url = new URL(authority);
  url.pathname = pathname
    .replace(/\[([A-z]{1}[A-z0-9]+)\]/, function (match, p1) {
      if (query[p1]) {
        const queryValue = query[p1];
        // Supprime le query-parameter car il s’agit en réalité d’un paramètre de l’URL.
        delete query[p1];
        return queryValue;
      }
      return match;
    })
    .replace(/\/\[\[\.\.\.([A-z]{1}[A-z0-9]+)\]\]/, function (match, p1) {
      // Si la valeur est un tableau, on la transforme en chaîne de caractères.
      if (Array.isArray(query[p1])) {
        const queryValue = query[p1].join("/");
        // Supprime le query-parameter car il s’agit en réalité d’un paramètre de l’URL.
        delete query[p1];
        return `/${queryValue}`;
      }
      if (!query[p1]) {
        return "";
      }
      // Sinon, on n’effectue pas de remplacement.
      return match;
    });

  url.search = new URLSearchParams(query).toString();

  return url.href;
}
