module.exports = {
  root: "../",
  locales: ["fr", "en", "de", "it", "es", "pt", "pl", "nl", "ro"],
  mainLocale: "fr",
  overrideMainLocale: true,
  exclude: ["**/node_modules/**", "(*-test).js", "**/.turbo/**", "**/*.d.ts"],
  dependencies: [
    {
      packagePath: "../legacy-resources",
      paths: "*",
    },
    {
      packagePath: "../shared",
      paths: "*",
    },
  ],
};
