import React from "react";
import { View, StyleSheet } from "react-native";

type IProps = {
  maxWidth?: number | string;
};

const styles = StyleSheet.create({
  modalBody: {
    position: "relative",
    width: "100%",
    paddingTop: "1em",
    paddingBottom: "1em",
  },
});

const ModalBody: React.FunctionComponent<IProps> = (props) => {
  const { maxWidth = "56.25em", children } = props;

  return <View style={[styles.modalBody, { maxWidth }]}>{children}</View>;
};

export default ModalBody;
