/**
 * Initial compare function
 * @param {any} item1
 * @param {any} item2
 * @param {any[]} array
 * @returns {number}
 */
function initialCompare(item1, item2, array) {
  return array.indexOf(item1) - array.indexOf(item2);
}

/**
 * Sort an array
 * @param {any[]} arr
 * @param {Function} compare
 * @returns {any[]}
 */
export default function arraySort(arr, compare = initialCompare) {
  const sortedArray = [...arr];
  sortedArray.sort((item1, item2) => compare(item1, item2, arr));
  return sortedArray;
}
