// libraries
import { generateUri } from "@cloudspire/legacy-shared/src/libraries";

// helpers
import generateUriRelayOnType from "../generateUriRelayOnType";

/**
 * Génère les différents liens du fil d'Ariane.
 */
const generateBreacrumbItemList = ({ content, intl, route, router }) => {
  return [
    {
      label: intl.formatMessage({
        defaultMessage: "Accueil",
      }),
      to: generateUri({
        router,
        name: "App.Action.Home",
        parameters: {
          lang: route.getParameter("lang"),
        },
      }),
    },
    ...("App.Action.Campaign" === route.getName()
      ? [
          {
            label: intl.formatMessage({
              defaultMessage: "Thématiques",
            }),
            to: generateUri({
              router,
              name: "App.Action.CampaignList",
              parameters: {
                lang: route.getParameter("lang"),
              },
            }),
          },
        ]
      : []),
    {
      label: content.title,
      to: generateUriRelayOnType({ intl, content, frontRouter: router }),
    },
  ];
};

export default generateBreacrumbItemList;
