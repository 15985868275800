// libraries
import { Router } from "../../libraries/Router";

// constants (computed)
const SLUG_REGEX = /[A-z0-9-%']+/;
const ROOM_REFERENCE_REGEX = /[hH]\d{2}[ghGH]\d{6}/;

const frontRouter = new Router();

frontRouter.group("/api", (frontRouter) => {
  frontRouter.group("/sso", (frontRouter) => {
    frontRouter.get("/redirect").setName("Front.Api.Sso.Redirect");
    frontRouter.get("/callback/gdf").setName("Front.Api.Sso.Callback.Gdf");
  });
});

frontRouter.group("/:lang", (frontRouter) => {
  frontRouter.get("/_error").setPage("notFound").setName("App.NotFound");

  frontRouter.setConstrain("lang", /[a-z]{2,}/);
  frontRouter.setPathFor("lang", "fr", "/:lang");

  frontRouter
    .get("/sso-callback")
    .setPage("sso-callback")
    .setName("App.Action.SsoCallback");

  // Index
  frontRouter.get("").setPage("index").setName("App.Action.Home");

  // Room list
  frontRouter
    .get("/recherche/:slug")
    .setPage("rechercher")
    .setName("App.Action.RoomListWithSlug")
    .setConstrain("slug", SLUG_REGEX)
    .setAncestor((frontRouter) => frontRouter.findByName("App.Action.Home"));

  // Room list
  frontRouter
    .get("/recherche")
    .setPage("rechercher")
    .setName("App.Action.RoomList")
    .setConstrain("slug", SLUG_REGEX);

  // Campaign
  frontRouter
    .get("/thematiques/:slug")
    .setPage("thematiques/[slug]")
    .setName("App.Action.Campaign")
    .setConstrain("slug", SLUG_REGEX)
    .setAncestor((frontRouter) => frontRouter.findByName("App.Action.Home"));

  // Campaign list
  frontRouter
    .get("/thematiques")
    .setPage("thematiques")
    .setName("App.Action.CampaignList");

  // City list
  frontRouter.get("/villes").setPage("villes").setName("App.Action.CityList");

  // Bon plan
  frontRouter
    .get("/bons-plans")
    .setPage("bons-plans")
    .setName("App.Action.DealList");

  // Room
  frontRouter
    .get("/location/:reference")
    .setPage("location/[slug]")
    .setName("App.Action.RoomWithRef")
    .setConstrain("reference", ROOM_REFERENCE_REGEX);

  // Room
  frontRouter
    .get("/location/:slug")
    .setPage("location/[slug]")
    .setName("App.Action.RoomWithSlug")
    .setConstrain("slug", SLUG_REGEX);

  // Contact
  frontRouter.get("/contact").setPage("contact").setName("App.Action.Contact");

  // Message
  frontRouter.get("/message").setPage("message").setName("App.Action.Message");

  // Content
  frontRouter
    .get("/page/:slug")
    .setPage("page/[slug]")
    .setName("App.Action.Content")
    .setConstrain("slug", SLUG_REGEX)
    .setAncestor((frontRouter) => frontRouter.findByName("App.Action.Home"));
});

export default frontRouter;
