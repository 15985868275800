// dependencies
import React, { useContext, useMemo } from "react";
import { useAuth } from "./AuthContext";
import { useRouter } from "next/router";

const requestContext = React.createContext<{
  channelId: string;
  onUnauthorized: () => void;
}>(null);

type ProviderPropsType = {
  requestContext: {
    channelId: string;
  };
};

export const RequestContextProvider: React.FunctionComponent<
  ProviderPropsType
> = (props) => {
  const { requestContext: value, ...otherProps } = props;

  const { channelId } = value;

  const Provider = requestContext.Provider;

  return <Provider {...otherProps} value={{ channelId }} />;
};

export const RequestContextConsumer = requestContext.Consumer;

export const useRequestContext = () => {
  const context = useContext(requestContext);

  const { token, onShouldDeleteToken } = useAuth();

  const channelId = context.channelId;

  const { locale } = useRouter();

  return useMemo(
    () => ({
      token,
      channelId,
      locale,
      onUnauthorized: onShouldDeleteToken,
    }),
    [token, channelId, locale, onShouldDeleteToken]
  );
};

export default requestContext;
