// dependencies
import React from "react";
import { View, StyleSheet } from "react-native";

type IProps = {};

const styles = StyleSheet.create({
  modalBottom: {
    backgroundColor: "#cfdadd",
    padding: "1em",
  },
});

const ModalFooter: React.FunctionComponent<IProps> = (props) => {
  const { children } = props;

  return <View style={styles.modalBottom}>{children}</View>;
};

export default ModalFooter;
