// dependencies
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

// components
import Layout, {
  ClassicLink,
  LabelLink,
  SearchLink,
  Logo,
  TextLogo,
} from "@cloudspire/legacy-resources/src/components/Layout";
import CookieConsent from "@cloudspire/legacy-resources/src/components/CookieConsent";
import Backdrop from "@cloudspire/legacy-resources/src/components/Backdrop";
import Modal, {
  ModalBody,
  ModalContent,
  ModalHeader,
} from "@cloudspire/legacy-resources/src/components/Modal";
import Text from "@cloudspire/legacy-resources/src/components/Text2";

// containers
import NavigationLocaleSelect from "@cloudspire/legacy-resources/src/containers/NavigationLocale/Select";

// contexts
import { useConfiguration } from "@cloudspire/legacy-resources/src/contexts/configuration";
import { useRoute } from "@cloudspire/legacy-resources/src/contexts/route";
import { useRouters } from "@cloudspire/legacy-resources/src/contexts/routers";
import { useGoogleTracking } from "@cloudspire/legacy-resources/src/contexts/GoogleTracking";

// constants
import { CONTENT_ALIAS_VALUE_LABEL } from "@cloudspire/shared/constants/content";

// libraries
import EnhancedDate from "@cloudspire/legacy-resources/src/libraries/EnhancedDate";
import { formatPhone } from "@cloudspire/legacy-resources/src/libraries";
import {
  encodeQuery,
  generateUri,
} from "@cloudspire/legacy-shared/src/libraries";

// helpers
import {
  layoutGetBottomBarLinkList,
  layoutGetHeaderMenuLinkList,
  layoutGetMainFooterColumnList,
  layoutGetMainNavigationLinkList,
  layoutGetSocialLinkList,
} from "@cloudspire/legacy-resources/src/helpers/layout";

// hooks
import useDisableScroll from "@cloudspire/legacy-resources/src/hocs/useDisableScroll";

type PropsType = {};

const LayoutContainer: React.FunctionComponent<PropsType> = (props) => {
  const { children } = props;

  const channelConfiguration = useConfiguration();

  const intl = useIntl();

  const { frontRouter, accountRouter } = useRouters();

  const route = useRoute();

  const routeUri = route.getUri();

  const { renderBanner, acceptCookies, refuseCookies } = useGoogleTracking();

  const currentPath = route.generateUri().getPath();

  const [ssoError, setSsoError] = useState(
    "1" === routeUri.getParsedQuery().sso_error
  );

  /**
   * Gère la fermeture de la modal d'erreur SSO.
   */
  function handleCloseSsoError() {
    setSsoError(false);

    const newQuery = routeUri.getParsedQuery();
    delete newQuery.sso_error;

    // Supprime le paramètre de l'URL.
    history.replaceState(
      {},
      "",
      routeUri.withQuery(encodeQuery(newQuery) || null).toString()
    );
  }

  useDisableScroll(renderBanner);

  return (
    <Backdrop
      isVisible={renderBanner}
      content={
        <CookieConsent
          onPressAccept={acceptCookies}
          onPressRefuse={refuseCookies}
        />
      }
    >
      {ssoError && (
        <Modal
          opened={ssoError}
          onHide={handleCloseSsoError}
          onShouldClose={handleCloseSsoError}
        >
          <ModalBody>
            <ModalHeader onShouldClose={handleCloseSsoError}>
              <Text style={{ color: "#ffffff" }}>
                <FormattedMessage defaultMessage="Erreur de connexion" />
              </Text>
            </ModalHeader>

            <ModalContent>
              <Text>
                <div style={{ padding: "1rem" }}>
                  <FormattedMessage defaultMessage="Une erreur est survenue lors de la connexion à votre compte." />
                </div>
              </Text>
            </ModalContent>
          </ModalBody>
        </Modal>
      )}
      <Layout
        headerMenuLinkList={layoutGetHeaderMenuLinkList({
          frontRouter,
          accountRouter,
          configuration: channelConfiguration,
          intl,
          currentPath,
        })}
        mainNavigationLinkList={layoutGetMainNavigationLinkList({
          frontRouter,
          configuration: channelConfiguration,
          intl,
          currentPath,
        })}
        mainFooterColumnList={layoutGetMainFooterColumnList({
          frontRouter,
          configuration: channelConfiguration,
          intl,
        })}
        bottomBarLinkList={layoutGetBottomBarLinkList({
          frontRouter,
          configuration: channelConfiguration,
          intl,
        })}
        socialLinkList={layoutGetSocialLinkList({
          configuration: channelConfiguration,
        })}
        copyright={`\u00a9\u00a0${EnhancedDate.fromDate(
          new Date(Date.now())
        ).getYear()} ${channelConfiguration.channel.title}`}
        socialLabel={intl.formatMessage(
          {
            defaultMessage:
              "Retrouvez la marque {label} sur vos réseaux préférés",
          },
          { label: channelConfiguration.channel.title }
        )}
        phoneNumber={formatPhone(
          channelConfiguration.channel.informations.booking.phone
        )}
        openingTime={channelConfiguration.channel.informations.booking.schedule}
        highlightLinkList={[
          <SearchLink
            linkProps={{
              dataSet: {
                gtm: "header-page-room-list",
              },
            }}
            key="search"
            to={generateUri({
              router: frontRouter,
              name: "App.Action.RoomList",
              parameters: {
                lang: intl.locale,
              },
            })}
          >
            {intl.formatMessage({
              defaultMessage: "Rechercher",
            })}
          </SearchLink>,

          ...(channelConfiguration.channel.website.contents.byAliases[
            CONTENT_ALIAS_VALUE_LABEL
          ]
            ? [
                <LabelLink
                  key="label"
                  to={generateUri({
                    router: frontRouter,
                    name: "App.Action.Content",
                    parameters: {
                      lang: intl.locale,
                      slug: channelConfiguration.channel.website.contents
                        .byAliases[CONTENT_ALIAS_VALUE_LABEL].slug,
                    },
                  })}
                >
                  {
                    channelConfiguration.channel.website.contents.byAliases[
                      CONTENT_ALIAS_VALUE_LABEL
                    ].title
                  }
                </LabelLink>,
              ]
            : []),
          <ClassicLink
            key="contact"
            linkProps={{
              dataSet: {
                gtm: "header-page-contact",
              },
            }}
            to={frontRouter
              .findByName("App.Action.Contact")
              .toFilled()
              .setParameter("lang", intl.locale)
              .generateUri()
              .toString()}
          >
            {intl.formatMessage({
              defaultMessage: "Nous contacter",
            })}
          </ClassicLink>,
          <ClassicLink
            key="login"
            to={generateUri({
              router: accountRouter,
              name: "App.Action.Auth.Login",
              parameters: {
                lang: intl.locale,
              },
            })}
          >
            {intl.formatMessage({
              defaultMessage: "Se connecter",
            })}
          </ClassicLink>,
        ]}
        textLogo={
          <TextLogo
            to={`https://www.${channelConfiguration.channel.website.domain}`}
            logoSource={{
              uri: channelConfiguration.channel.medias.logo,
            }}
          />
        }
        logo={
          <Logo
            logoSource={{ uri: channelConfiguration.channel.medias.avatar }}
            title={channelConfiguration.channel.website.title}
            description={channelConfiguration.channel.website.description}
          />
        }
        localeSelect={
          1 < channelConfiguration.translation.locales.length && (
            <NavigationLocaleSelect />
          )
        }
      >
        {children}
      </Layout>
    </Backdrop>
  );
};

export default LayoutContainer;
