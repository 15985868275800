// constants
import browser from "../../constants/browser";
import { NEXT_INTL_MESSAGES_WINDOW_KEY } from "../../constants/next";

/**
 * Stocke les messages de traduction dans l'objet `window`.
 * @param {object} param0
 * @param {object} param0.intlMessages
 */
export default function nextSetIntlMessages({ locale, intlMessages }) {
  if (browser) {
    if (!window[NEXT_INTL_MESSAGES_WINDOW_KEY]) {
      window[NEXT_INTL_MESSAGES_WINDOW_KEY] = {};
    }
    window[NEXT_INTL_MESSAGES_WINDOW_KEY][locale] = intlMessages;
  }
}
