// dependencies
import React from "react";
import { View, StyleSheet } from "react-native";
import { injectIntl } from "react-intl";
import {
  IconFacebook,
  IconTwitter,
  IconPinterest,
  IconInstagram,
  IconTumblr,
  IconLinkedin,
  IconViadeo,
  IconYoutube,
  IconTiktok,
} from "@cloudspire/legacy-svg-icon-library";

// components
import Text from "../../Text";
import Link from "../../Link";

// constants
import {
  CHANNEL_SOCIALS,
  CHANNEL_SOCIAL_VALUE_FACEBOOK,
  CHANNEL_SOCIAL_VALUE_INSTAGRAM,
  CHANNEL_SOCIAL_VALUE_LINKEDIN,
  CHANNEL_SOCIAL_VALUE_PINTEREST,
  CHANNEL_SOCIAL_VALUE_TUMBLR,
  CHANNEL_SOCIAL_VALUE_TWITTER,
  CHANNEL_SOCIAL_VALUE_VIADEO,
  CHANNEL_SOCIAL_VALUE_YOUTUBE,
  CHANNEL_SOCIAL_VALUE_TIKTOK,
} from "@cloudspire/shared/constants/channel";

const iconElementMap = {
  [CHANNEL_SOCIAL_VALUE_FACEBOOK]: IconFacebook,
  [CHANNEL_SOCIAL_VALUE_TWITTER]: IconTwitter,
  [CHANNEL_SOCIAL_VALUE_PINTEREST]: IconPinterest,
  [CHANNEL_SOCIAL_VALUE_INSTAGRAM]: IconInstagram,
  [CHANNEL_SOCIAL_VALUE_TUMBLR]: IconTumblr,
  [CHANNEL_SOCIAL_VALUE_LINKEDIN]: IconLinkedin,
  [CHANNEL_SOCIAL_VALUE_VIADEO]: IconViadeo,
  [CHANNEL_SOCIAL_VALUE_YOUTUBE]: IconYoutube,
  [CHANNEL_SOCIAL_VALUE_TIKTOK]: IconTiktok,
};

type IProps = {
  social;
  to: string;
  intl: import("react-intl").IntlShape;
};

const SocialIcon = (props: IProps): JSX.Element => {
  const { social, intl, to } = props;

  const {
    id: socialName,
    color: socialColor,
    label: socialLabel,
  } = CHANNEL_SOCIALS[social];

  const styles = StyleSheet.create({
    view: {
      width: "2.5em",
      height: "2.5em",
    },
    text: {
      fontSize: "1.25em",
      display: "flex",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      borderStyle: "solid",
      borderColor: socialColor,
      color: socialColor,
      lineHeight: 0,
      borderTopWidth: "0.1em" as any,
      borderRightWidth: "0.1em" as any,
      borderBottomWidth: "0.1em" as any,
      borderLeftWidth: "0.1em" as any,
      borderRadius: "50%" as any,
    },
  });

  const Icon = iconElementMap[socialName] ?? (() => null);

  const label = intl.formatMessage(
    {
      defaultMessage: "Retrouvez-nous sur {social}",
    },
    { social: socialLabel }
  );

  return (
    <Text>
      <View style={styles.view}>
        <Link target="_blank" to={to} style={styles.text} title={label}>
          <Icon />
        </Link>
      </View>
    </Text>
  );
};

export default injectIntl(SocialIcon);
