// dependencies
import React, { useCallback, useState } from "react";
import { View, StyleSheet, TouchableWithoutFeedback } from "react-native";
import { useIntl } from "react-intl";

// components
import BaseComponent from "@cloudspire/legacy-resources/src/components/BaseComponent";
import Stack from "@cloudspire/legacy-resources/src/components/Stack";
import Text from "@cloudspire/legacy-resources/src/components/Text";
import Button from "@cloudspire/legacy-resources/src/components/Button";
import UIContainer from "@cloudspire/legacy-resources/src/components/UIContainer";
import Media from "@cloudspire/legacy-resources/src/components/Media";
import { Row, Col } from "@cloudspire/legacy-resources/src/components/Grid";
import {
  List,
  ListItem,
} from "@cloudspire/legacy-resources/src/components/List";
import {
  IconCaretDown,
  IconCaretUp,
} from "@cloudspire/legacy-svg-icon-library";

// libraries
import { emToPx } from "@cloudspire/legacy-resources/src/libraries";

type PropsType = {
  onPressAccept: () => void;
  onPressRefuse: () => void;
};

const styles = StyleSheet.create({
  cookieConsent: {
    backgroundColor: "#f8f3ea",
  },
  container: {
    padding: emToPx(1),
  },
  detail: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  detailContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  detailText: {
    fontSize: emToPx(0.5),
  },
});

const CookieConsent: React.FunctionComponent<PropsType> = (props) => {
  const { onPressAccept, onPressRefuse } = props;

  const intl = useIntl();

  const [detailOpened, setDetailOpened] = useState(false);

  const handleClickDetail = useCallback(() => {
    setDetailOpened(!detailOpened);
  }, [detailOpened]);

  return (
    <BaseComponent containerStyle={styles.cookieConsent}>
      <UIContainer isFullWidth={true}>
        <View style={styles.container}>
          <Row
            breakpointList={{ sm: 700, md: Infinity }}
            col={{ sm: 1, md: 3 }}
            gutter={0.5}
          >
            <Col span={{ sm: 1, md: 2 }}>
              <List gutter={0.5}>
                <ListItem>
                  <Text style={{ fontSize: emToPx(1.25) }}>
                    {intl.formatMessage({
                      defaultMessage: "Ce site web utilise des cookies",
                    })}
                  </Text>
                </ListItem>

                <ListItem>
                  <Media query={{ minWidth: 700 }}>
                    {(matches) => (
                      <>
                        {!matches && (
                          <>
                            <View style={styles.detail}>
                              <TouchableWithoutFeedback
                                onPress={handleClickDetail}
                              >
                                <View style={styles.detailContainer}>
                                  <Text>{`${intl.formatMessage({
                                    defaultMessage: "En savoir plus",
                                  })} `}</Text>
                                  <Text style={styles.detailText}>
                                    {detailOpened ? (
                                      <IconCaretUp />
                                    ) : (
                                      <IconCaretDown />
                                    )}
                                  </Text>
                                </View>
                              </TouchableWithoutFeedback>
                            </View>

                            <Stack marginTop={0.5} />
                          </>
                        )}

                        {(matches || detailOpened) && (
                          <Text style={{ fontSize: emToPx(0.75) }}>
                            {intl.formatMessage({
                              defaultMessage:
                                "Nous utilisons les cookies afin de fournir les services et fonctionnalités proposés sur notre site et afin d’améliorer l’expérience de nos utilisateurs. Les cookies sont des données qui sont téléchargés ou stockés sur votre ordinateur ou sur tout autre appareil.\nEn cliquant sur ”J’accepte”, vous acceptez l’utilisation des cookies. Vous pourrez toujours les désactiver ultérieurement. Si vous supprimez ou désactivez nos cookies, vous pourriez rencontrer des interruptions ou des problèmes d’accès au site.",
                            })}
                          </Text>
                        )}
                      </>
                    )}
                  </Media>
                </ListItem>
              </List>
            </Col>

            <Col>
              <List gutter={1}>
                <ListItem>
                  <Button
                    accessibilityLabel={intl.formatMessage({
                      defaultMessage: "Je refuse l’utilisation de cookies",
                    })}
                    onPress={onPressRefuse}
                  >
                    {intl.formatMessage({
                      defaultMessage: "Je refuse l’utilisation de cookies",
                    })}
                  </Button>
                </ListItem>

                <ListItem>
                  <Button
                    color="reversed-brand"
                    accessibilityLabel={intl.formatMessage({
                      defaultMessage: "J’accepte tous les cookies",
                    })}
                    onPress={onPressAccept}
                  >
                    {intl.formatMessage({
                      defaultMessage: "J’accepte tous les cookies",
                    })}
                  </Button>
                </ListItem>
              </List>
            </Col>
          </Row>
        </View>
      </UIContainer>
    </BaseComponent>
  );
};

export default CookieConsent;
