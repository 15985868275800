import encodeQuery from "../encodeQuery";

/**
 *
 * @param param0 Modifie l'URL avec les query-params formattées.
 */
const searchPushToQuery = ({ query }) => {
  return window.history.replaceState(
    null,
    null,
    `?${encodeQuery({
      ...query,
      page: query.page === "1" ? undefined : query.page,
    })}`
  );
};

export default searchPushToQuery;
