// dependencies
import React, { useEffect, useState, useCallback } from "react";
import { View, Dimensions } from "react-native";

// libraries
import getBreakpoint from "../libraries/utils/getBreakpoint";

export type WithBreakpointInjectedPropsType = {
  breakpoint: string;
};

function withBreakpoint(WrappedComponent) {
  return function WrapperComponent(props) {
    const {
      breakpointType = "viewport",
      breakpointList,
      ...otherProps
    } = props;

    const [breakpoint, setBreakpoint] = useState(
      getBreakpoint({
        width: undefined,
        breakpointList: props.breakpointList,
      })
    );

    /**
     * Déclenche le calcul du `breakpoint` actif
     */
    const handleChangeDimensions = useCallback(
      function handleChangeDimensions({
        window,
      }: {
        window: import("react-native").ScaledSize;
      }) {
        setBreakpoint(getBreakpoint({ width: window.width, breakpointList }));
      },
      [breakpointList]
    );

    const handleLayout = useCallback(function handleLayout(
      event: import("react-native").LayoutChangeEvent
    ) {
      setBreakpoint(getBreakpoint({ width: event.nativeEvent.layout.width }));
    },
    []);

    useEffect(
      function () {
        if ("viewport" === breakpointType) {
          Dimensions.addEventListener("change", handleChangeDimensions);

          handleChangeDimensions({
            window: Dimensions.get("window"),
          });

          return function () {
            Dimensions.removeEventListener("change", handleChangeDimensions);
          };
        }
      },
      [breakpointType, handleChangeDimensions]
    );

    return (
      <View {...("element" === breakpointType && { onLayout: handleLayout })}>
        <WrappedComponent {...otherProps} breakpoint={breakpoint} />
      </View>
    );
  };
}

export default withBreakpoint;
