// deps
import React from "react";
import { useRouter } from "next/router";
import { StyleSheet, css } from "aphrodite";

// components
import Select from "../../components/Select2/Select";

// contexts
import { useConfiguration } from "../../contexts/configuration";

// utils
import stringUppercaseFirst from "@cloudspire/shared/utils/string/uppercaseFirst";
import arraySort from "@cloudspire/shared/utils/array/sort";
import arrayFilter from "@cloudspire/shared/utils/array/filter";
import arrayMap from "@cloudspire/shared/utils/array/map";
import objectHasProperty from "@cloudspire/shared/utils/object/hasProperty";

// libraries
import { generateUri } from "@cloudspire/legacy-shared/src/libraries";

// constants
import mediasRouter from "@cloudspire/legacy-shared/src/constants/router/mediasRouter";
import {
  TRANSLATION_SUPPORTED_LOCALES,
  TRANSLATION_SOURCE_LOCALE,
} from "@cloudspire/shared/constants/translation";

const styles = StyleSheet.create({
  localeOption: {
    display: "flex",
    alignItems: "center",
  },
  localeImgWrapper: {
    marginRight: "0.5rem",
  },
  localeImg: {
    width: "1rem",
  },
  localeList: {
    minWidth: 200,
  },
  caret: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.3125rem",
  },
});

const supportedLocales = {
  ...TRANSLATION_SUPPORTED_LOCALES,
  [TRANSLATION_SOURCE_LOCALE.id]: TRANSLATION_SOURCE_LOCALE,
};

type Props = {
  display: "compact" | "link";
  popoverProps?: any;
};

function NavigationLocaleSelect(props: Props) {
  const { display = "compact", popoverProps } = props;

  const router = useRouter();

  const { locale, asPath } = router;

  /**
   * Gère le changement de langue
   */
  function handleChange(event) {
    window.location.href = `/${event.target.value}${asPath}`;
  }

  const configuration = useConfiguration();

  const locales = arraySort(
    arrayMap(
      arrayFilter(configuration.translation.locales, function (locale) {
        return objectHasProperty(supportedLocales, locale);
      }),
      function (locale) {
        return supportedLocales[locale];
      }
    ),
    (locale1, locale2) => {
      return locale1.nativeLabel.localeCompare(locale2.nativeLabel);
    }
  );

  return (
    <Select
      popoverProps={popoverProps}
      name="locale"
      native={false}
      value={locale}
      shouldRenderBorder={"link" === display}
      shouldRenderCaret={true}
      caretType="caret"
      caretStyle={styles.caret}
      listboxStyle={styles.localeList}
      optionList={locales.map((locale) => ({
        id: locale.id,
        value: locale.id,
        selectedLabel:
          "compact" === display ? (
            /* eslint-disable-next-line @next/next/no-img-element */
            <img
              className={css(styles.localeImg)}
              src={generateUri({
                router: mediasRouter,
                name: "home",
                parameters: {
                  path: locale.logoPath,
                },
              })}
              alt={stringUppercaseFirst(locale.nativeLabel)}
              loading="lazy"
            />
          ) : null,
        label: (
          <div className={css(styles.localeOption)}>
            <span className={css(styles.localeImgWrapper)}>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                className={css(styles.localeImg)}
                src={generateUri({
                  router: mediasRouter,
                  name: "home",
                  parameters: {
                    path: locale.logoPath,
                  },
                })}
                alt={stringUppercaseFirst(locale.nativeLabel)}
                loading="lazy"
              />
            </span>
            {stringUppercaseFirst(locale.nativeLabel)}
          </div>
        ),
      }))}
      onChange={handleChange}
    />
  );
}

export default NavigationLocaleSelect;
