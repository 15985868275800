// dependencies
import React, { useState, useCallback, useMemo, cloneElement } from "react";
import { useIntl } from "react-intl";
import { StyleSheet, css } from "aphrodite";

// components
import { IconMenuO, IconCrossO } from "@cloudspire/legacy-svg-icon-library";
import Text from "@cloudspire/legacy-resources/src/components/Text2";
import Portal from "@cloudspire/legacy-resources/src/components/Portal";
import Drawer from "@cloudspire/legacy-resources/src/components/Drawer";
// import { LinkMenu } from "@cloudspire/legacy-resources/src/components/Link";
import Link from "./Link";

// constants
import theming from "@cloudspire/legacy-resources/src/constants/theming";

// libraries
import { capitalize } from "@cloudspire/legacy-shared/src/libraries";

const { useTheme } = theming;

export type LinkType = {
  label: string;
  to: string;
  isActivated: boolean;
};

type PropsType = {
  selector: string;
  linkList?: LinkType[];
  localeSelect: React.ReactNode;
};

const styles = StyleSheet.create({
  menu: {
    cursor: "pointer",
  },
  menuList: {
    display: "flex",
    flexDirection: "column",
    minWidth: 250,
    flexGrow: 1,
  },
  icon: {
    fontSize: "1.125rem",
  },
  label: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.875rem",
  },
  header: {
    display: "flex",
    padding: "0.5rem",
    height: "3.125rem",
    overflow: "hidden",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerText: {
    color: "#ffffff",
  },
  close: {
    color: "#ffffff",
    cursor: "pointer",
    position: "relative",
    ":before": {
      content: '""',
      position: "absolute",
      top: "-0.625rem",
      right: "-0.625rem",
      bottom: "-0.625rem",
      left: "-0.625rem",
    },
  },
  linkList: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  navigation: {
    padding: ".625rem",
  },
});

const MenuDropdown: React.FunctionComponent<PropsType> = (props) => {
  const { selector, linkList = [], localeSelect } = props;

  const intl = useIntl();

  const [opened, setOpened] = useState(false);

  const theme = useTheme();

  const label = intl.formatMessage({
    defaultMessage: "Menu",
  });

  const dynamicStyles = useMemo(
    () =>
      StyleSheet.create({
        header: {
          backgroundColor: theme.PRIMARY_COLOR,
        },
      }),
    [theme]
  );

  const handleShouldClose = useCallback(() => {
    setOpened(false);
  }, []);

  const handlePressOpen = useCallback(() => setOpened(true), []);

  return (
    <>
      <Portal selector={selector}>
        <Drawer isOpened={opened} onShouldClose={handleShouldClose}>
          <nav
            className={css(styles.menuList)}
            aria-label={intl.formatMessage({
              defaultMessage: "Menu principal",
            })}
          >
            <div>
              <div className={css(styles.header, dynamicStyles.header)}>
                <Text aStyle={styles.headerText}>
                  {capitalize(
                    intl.formatMessage({
                      defaultMessage: "bienvenue",
                    })
                  )}
                </Text>

                <Text
                  role="button"
                  aria-label={intl.formatMessage({
                    defaultMessage: "Fermer",
                  })}
                  aStyle={styles.close}
                  onClick={handleShouldClose}
                >
                  <IconCrossO />
                </Text>
              </div>
            </div>

            <div className={css(styles.linkList)}>
              {linkList.map((link, index) => {
                const { label, to, isActivated } = link;

                return (
                  <Link
                    key={index}
                    label={label}
                    isActivated={isActivated}
                    to={to}
                    onPress={handleShouldClose}
                  />
                );
              })}
            </div>

            {localeSelect && (
              <div className={css(styles.navigation)}>
                {cloneElement(localeSelect, {
                  display: "link",
                  popoverProps: {
                    zIndex: 9999,
                    style: { pointerEvents: "auto" },
                  },
                })}
              </div>
            )}
          </nav>
        </Drawer>
      </Portal>
      <div onClick={handlePressOpen}>
        <Text
          aStyle={styles.menu}
          role="button"
          aria-label={label}
          aria-expanded={opened}
          aria-pressed={opened}
          aria-haspopup={true}
        >
          <Text aStyle={styles.label}>
            <IconMenuO />
            &nbsp;{label}
          </Text>
        </Text>
      </div>
    </>
  );
};

export default MenuDropdown;
