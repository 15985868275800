// utils
import get from "../../utils/get";

/**
 * Retourne le domaine du site
 * @param {object} param0
 * @param {object} param0.channelConfiguration
 * @param {string} param0.websiteType
 * @param {string} [param0.bookingDelegationChannelId]
 * @returns {string}
 */
export default function channelBuildHostFromConfiguration({
  channelConfiguration,
  websiteType,
  bookingDelegationChannelId,
}) {
  const domain = get(channelConfiguration, "channel.website.domain");
  const subdomain = get(
    channelConfiguration,
    `channel.website.${websiteType}.subdomain`,
  );

  // Si le sous-domaine n’est pas défini
  if (!subdomain) {
    // Si le type de site est le site en marque blanche
    if ("front" === websiteType) {
      return domain;
    }
    // Sinon, on lève une erreur car le sous-domaine n’est pas défini
    throw new Error("Le sous-domaine n'est pas défini");
  }
  // Si le type de site est le site de réservation et que la délégation de réservation est activée
  if (
    "booking" === websiteType &&
    bookingDelegationChannelId &&
    get(channelConfiguration, "channel.website.use_booking_delegation", false)
  ) {
    const delegatedChannel = get(
      channelConfiguration,
      `channel.website.delegated_channels.${bookingDelegationChannelId}`,
    );
    // Si le canal de distribution délégué est défini
    if (delegatedChannel) {
      return channelBuildHostFromConfiguration({
        channelConfiguration: {
          channel: {
            ...delegatedChannel,
            website: {
              ...delegatedChannel?.website,
              domain: delegatedChannel?.domain,
            },
          },
        },
        websiteType: "booking",
      });
    }
  }
  return `${subdomain}.${domain}`;
}
