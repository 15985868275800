import EnhancedDate from "../EnhancedDate";

/**
 * Retourne le plus grande date passée en paramètre.
 */
const max = (...dateList: EnhancedDate[]) => {
  return dateList.reduce((maxDate, date) => {
    if (
      date instanceof EnhancedDate &&
      (null === maxDate || date.isGreaterThan(maxDate))
    ) {
      return date;
    }

    return maxDate;
  }, null);
};

export default max;
