// dependencies
import React from "react";
import {
  View,
  TouchableWithoutFeedback,
  GestureResponderEvent,
} from "react-native";
import { IconCrossO } from "@cloudspire/legacy-svg-icon-library";

type IProps = {
  onShouldClose: (event: GestureResponderEvent) => void;
};

const styles = {
  modalClose: {
    marginLeft: "1em",
    backgroundColor: "#000000",
    alignSelf: "baseline",
    padding: ".5em",
    borderRadius: "50%",
    borderStyle: "solid",
    borderColor: "white",
    borderWidth: "0.125em",
    cursor: "pointer",
  },
  icon: {
    color: "#ffffff",
    lineHeight: "1em",
  },
};

const ModalClose: React.FunctionComponent<IProps> = (props) => {
  const { onShouldClose } = props;

  return (
    <TouchableWithoutFeedback onPress={onShouldClose}>
      <View style={styles.modalClose}>
        <View style={styles.icon}>
          <IconCrossO />
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default ModalClose;
